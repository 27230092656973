import { addRDTTrackingURIQuery } from '@ridi-web/design-system/Utils';
import { useMemo } from 'react';

import {
  SearchComponentFlag,
  SearchComponentGenreRecommendationData,
  SearchComponentKeywordRecommendationData,
  useSearchComponentsQuery,
} from '@/queries/search/useSearchComponentsQuery';
import { sendCustomEvent } from '@/utils/eventClient';

import * as styles from './SearchKeywordList.styles';

const KeywordItemA = ({
  setName,
  name,
  landingUrl,
  setId,
  genreColorCode,
  index,
}: SearchComponentKeywordRecommendationData & { index: number }) => {
  const handleClick = () => {
    sendCustomEvent('search', 'select', 'keyword', {
      item_name: `${setName}#${name}`,
      item_index: index + 1,
      set_id: setId,
      set_name: setName,
    });

    globalThis.location.href = addRDTTrackingURIQuery(landingUrl, {
      sectionId: 'search_keyword',
    });
  };

  return (
    <button type="button" css={styles.keywordItemStyleA} onClick={handleClick}>
      <span css={styles.keywordItemGerneStyle(genreColorCode)}>{setName}</span>
      <span css={styles.keywordItemTagNameStyle}>{name}</span>
    </button>
  );
};

const KeywordItemB = ({
  setId,
  landingUrl,
  name,
  setName,
  index,
}: SearchComponentGenreRecommendationData & { index: number }) => {
  const handleClick = () => {
    sendCustomEvent('search', 'select', 'keyword', {
      item_name: setName,
      item_index: index + 1,
      set_id: setId,
      set_name: setName,
    });

    globalThis.location.href = addRDTTrackingURIQuery(landingUrl, {
      sectionId: 'search_keyword',
    });
  };

  return (
    <button type="button" css={styles.keywordItemStyleB} onClick={handleClick}>
      #{name}
    </button>
  );
};

type SearchKeywordListProps = Omit<ReturnType<typeof useSearchComponentsQuery>, 'isFallback'>;

export const SearchKeywordList = ({ data, error, isLoading }: SearchKeywordListProps) => {
  const [propsA, propsB] = useMemo(() => {
    const [dataA, dataB] = [data[SearchComponentFlag.Keyword] ?? null, data[SearchComponentFlag.Genre] ?? null];

    return [
      dataA
        ? {
            ...dataA,
            tags: dataA.data.tags as SearchComponentKeywordRecommendationData[],
          }
        : null,
      dataB
        ? {
            ...dataB,
            tags: dataB.data.tags as SearchComponentGenreRecommendationData[],
          }
        : null,
    ];
  }, [data]);

  const layoutA = useMemo(
    () =>
      propsA && (
        <div css={styles.keywordListContainerStyle}>
          <h2 css={styles.keywordListTitleStyle}>{propsA.name}</h2>
          <div css={styles.keywordListStyle}>
            {propsA.tags.map(({ genreName, genreColorCode, id, name, setId, setName, landingUrl }, i) => (
              <KeywordItemA
                id={id}
                index={i}
                genreName={genreName}
                genreColorCode={genreColorCode}
                name={name}
                setId={setId}
                setName={setName}
                landingUrl={landingUrl}
                key={`keyword-item-a-${i.toString()}`}
              />
            ))}
          </div>
        </div>
      ),
    [propsA],
  );

  const layoutB = useMemo(
    () =>
      propsB && (
        <div css={styles.keywordListContainerStyle}>
          <h2 css={styles.keywordListTitleStyle}>{propsB.name}</h2>
          <div css={styles.keywordListStyle}>
            {propsB.tags.map(({ genre, name, setId, setName, landingUrl }, i) => (
              <KeywordItemB
                genre={genre}
                index={i}
                name={name}
                setId={setId}
                setName={setName}
                landingUrl={landingUrl}
                key={`keyword-item-b-${i.toString()}`}
              />
            ))}
          </div>
        </div>
      ),
    [propsB],
  );

  return error || (!layoutA && !layoutB) ? (
    <div css={styles.emptyMarginStyle} />
  ) : (
    <div css={styles.containerStyle}>
      {layoutA}
      {layoutB}
    </div>
  );
};
