import { Record, Optional, Array as Array$1, String, Tuple } from 'runtypes';

var Currency;
(function(Currency) {
    Currency["KRW"] = "KRW";
    Currency["USD"] = "USD";
})(Currency || (Currency = {}));
var ServiceType;
(function(ServiceType) {
    ServiceType["RIDIBOOKS"] = "ridibooks";
    ServiceType["RIDISELECT"] = "ridiselect";
})(ServiceType || (ServiceType = {}));

var TargetHintPlatform;
(function(TargetHintPlatform) {
    TargetHintPlatform["IOS"] = "iOS";
    TargetHintPlatform["ANDROID"] = "Android";
})(TargetHintPlatform || (TargetHintPlatform = {}));
var TargetHintWebView;
(function(TargetHintWebView) {
    TargetHintWebView["NATIVE"] = "Native";
    TargetHintWebView["REACT_NATIVE"] = "ReactNative";
    TargetHintWebView["TEST"] = "Test";
})(TargetHintWebView || (TargetHintWebView = {}));

function asyncGeneratorStep$1(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator$1(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep$1(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep$1(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _type_of(obj) {
    "@swc/helpers - typeof";
    return obj && typeof Symbol !== "undefined" && obj.constructor === Symbol ? "symbol" : typeof obj;
}
function _ts_generator$1(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
var NativeTransport = function(onListenMessage, targetHint) {
    if (targetHint.webView && targetHint.webView !== TargetHintWebView.NATIVE) {
        return null;
    }
    if (!('inApp' in window && window.inApp)) {
        return null;
    }
    var inApp = window.inApp;
    var shouldSerializeJSON = targetHint.platform === TargetHintPlatform.ANDROID || !targetHint.platform && /android/i.test(navigator.userAgent);
    var inAppBridgeListener = function(event) {
        try {
            var message = event.data;
            if (!message || (typeof message === "undefined" ? "undefined" : _type_of(message)) !== 'object' || typeof message.name !== 'string') {
                throw new Error('Non InAppMessage');
            }
            if (!Array.isArray(message.args)) {
                message.args = [];
            }
            onListenMessage(event.data);
        } catch (e) {
        // 인 앱 메세지가 아님
        }
    };
    var availableMethods = Object.getOwnPropertyNames(inApp);
    var features = [];
    if (availableMethods.includes('logEvent')) {
        features.push('eventClient');
    }
    return {
        name: 'Native',
        features: features,
        initialize: function initialize() {
            window.addEventListener('message', inAppBridgeListener);
            document.addEventListener('message', inAppBridgeListener);
            return function() {
                window.removeEventListener('message', inAppBridgeListener);
                document.removeEventListener('message', inAppBridgeListener);
            };
        },
        send: function send(message) {
            var func = inApp[message.name];
            if (!func) {
                throw new Error('Not implemented method!');
            }
            var params = message.args;
            var serializedParams = params.map(function(argument) {
                if ([
                    'string',
                    'number',
                    'boolean'
                ].includes(typeof argument === "undefined" ? "undefined" : _type_of(argument))) {
                    return argument;
                }
                return JSON.stringify(argument);
            });
            var messageId = message.messageId;
            _async_to_generator$1(function() {
                var result, args, resultJSON;
                return _ts_generator$1(this, function(_state) {
                    switch(_state.label){
                        case 0:
                            if (!!('apply' in func)) return [
                                3,
                                2
                            ];
                            return [
                                4,
                                func
                            ];
                        case 1:
                            // iOS 에서 func가 함수가 아니라 promise인 상황
                            result = _state.sent();
                            return [
                                3,
                                7
                            ];
                        case 2:
                            if (!shouldSerializeJSON) return [
                                3,
                                3
                            ];
                            // Android에서 args => result인 상황
                            result = func.apply(inApp, serializedParams);
                            return [
                                3,
                                7
                            ];
                        case 3:
                            _state.trys.push([
                                3,
                                5,
                                ,
                                7
                            ]);
                            return [
                                4,
                                func.apply(inApp, params)
                            ];
                        case 4:
                            // iOS 로 먼저 시도해보고
                            result = _state.sent();
                            return [
                                3,
                                7
                            ];
                        case 5:
                            _state.sent();
                            return [
                                4,
                                func.apply(inApp, serializedParams)
                            ];
                        case 6:
                            // Android 로 Fallback한다
                            result = _state.sent();
                            return [
                                3,
                                7
                            ];
                        case 7:
                            args = [
                                result
                            ];
                            try {
                                if (typeof result === 'string') {
                                    // Primitive 밖에 반환하지 못하므로 JSON화 시켜 반환하기로 하였다
                                    // 대부분 이 경우일 것이므로 일단 파싱을 시도해본다
                                    resultJSON = JSON.parse(result);
                                    args = [
                                        resultJSON
                                    ];
                                }
                            } catch (e) {
                            // 파싱이 안될 경우 (레거시 네이티브 구현)에는 그냥 [string] 그대로 보낸다.
                            }
                            onListenMessage({
                                name: "".concat(message.name, "/").concat(messageId),
                                args: args,
                                messageId: "".concat(Date.now(), "_reply_").concat(messageId),
                                inApp: true
                            });
                            return [
                                2
                            ];
                    }
                });
            })().catch(function() {});
        }
    };
};

var ReactNativeTransport = function(onListenMessage, targetHint) {
    if (targetHint.webView && targetHint.webView !== TargetHintWebView.REACT_NATIVE) {
        return null;
    }
    if (!('ReactNativeWebView' in window)) {
        return null;
    }
    var inAppBridgeListener = function(event) {
        try {
            if (typeof event.data !== 'string') {
                return;
            }
            var _JSON_parse = JSON.parse(event.data), name = _JSON_parse.name, args = _JSON_parse.args, inApp = _JSON_parse.inApp, messageId = _JSON_parse.messageId;
            if (!inApp) {
                return;
            }
            if (typeof name === 'string' && Array.isArray(args)) {
                onListenMessage({
                    name: name,
                    args: args,
                    inApp: inApp,
                    messageId: messageId
                });
            }
        } catch (e) {
        // 인 앱 메세지가 아님
        }
    };
    return {
        name: 'ReactNative',
        features: [
            'eventClient'
        ],
        initialize: function initialize() {
            // iOS에서
            window.addEventListener('message', inAppBridgeListener);
            // 안드로이드에서
            document.addEventListener('message', inAppBridgeListener);
            return function() {
                window.removeEventListener('message', inAppBridgeListener);
                document.removeEventListener('message', inAppBridgeListener);
            };
        },
        send: function send(message) {
            var _window_ReactNativeWebView;
            (_window_ReactNativeWebView = window.ReactNativeWebView) === null || _window_ReactNativeWebView === void 0 ? void 0 : _window_ReactNativeWebView.postMessage(JSON.stringify(message));
        }
    };
};

var transportOptions = [
    NativeTransport,
    ReactNativeTransport
];

var isBrowser = function() {
    return typeof window !== 'undefined';
};

/**
 * @desc Parses untrusted version
 *   If it is a hard-coded constant, please use `appVersion`
 */ var parseVersion = function(version) {
    var _version_match__match, _version_match;
    var versions = (_version_match = version.match(/[\d.]+/)) === null || _version_match === void 0 ? void 0 : (_version_match__match = _version_match[0].match(/\d+/g)) === null || _version_match__match === void 0 ? void 0 : _version_match__match.map(Number);
    if (versions === null || versions === void 0 ? void 0 : versions.every(function(versionItem) {
        return Number.isInteger(versionItem);
    })) {
        return versions;
    }
    return null;
};
/**
 * @desc Tagged template for app versions, with type assertion
 * @example appVersion`23.1.1`
 */ var appVersion = function(version) {
    return parseVersion(version[0]);
};
/**
 * @desc Returns true if versionA is older than versionB
 * @example isVersionAOlder(myVersion, appVersion`23.1.1`)
 *   // if myVersion is 22.1.1, returns true
 *
 *   // If myVersion is 23.1.1, returns false
 *   // If myVersion is 23.1.2, returns false
 */ var isVersionAOlder = function(versionA, versionB) {
    for(var i = 0; i < Math.max(versionA.length, versionB.length); i += 1){
        // If prefix of two versions is same and B is shorter than A, A is newer
        // (23.1.2.1 > 23.1.2)
        if (versionB[i] === undefined) {
            return false;
        }
        if (versionA[i] === undefined) {
            return true;
        }
        // Length is same, diff with numbers
        // (23.3.2 > 23.1.2)
        if (versionA[i] > versionB[i]) {
            return false;
        }
        if (versionA[i] < versionB[i]) {
            return true;
        }
    }
    return false;
};

var RInAppInfo = Record({
    list: Optional(Array$1(String)),
    deviceId: Optional(String)
});

function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _tagged_template_literal(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
function _templateObject() {
    var data = _tagged_template_literal([
        "22.11.1"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
var EVENT_PARAM_NUMBER_LIMIT = 25;
var createMessageId = function() {
    return "".concat(Date.now(), "_").concat(Math.random().toString(36).slice(2, 8));
};
var createInAppBridge = function() {
    var options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    var _options_debug;
    var debug = (_options_debug = options.debug) !== null && _options_debug !== void 0 ? _options_debug : function() {};
    var eventListeners = new Map();
    var InAppBridge = {
        initialized: false,
        destroy: function() {},
        features: [],
        transport: undefined,
        targetHint: undefined,
        versionHint: undefined,
        initialize: function initialize() {
            var targetHint = (arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}).targetHint;
            var // eslint-disable-next-line no-underscore-dangle
            _window___InAppBridgeDevToolsHook_onTransportActive, _window___InAppBridgeDevToolsHook;
            if (InAppBridge.initialized) {
                return;
            }
            if (!isBrowser()) {
                return;
            }
            var onListen = function(message) {
                var name = message.name, args = message.args;
                if (typeof name === 'string' && Array.isArray(args)) {
                    var // eslint-disable-next-line no-underscore-dangle
                    _window___InAppBridgeDevToolsHook, _eventListeners_get;
                    debug('InAppBridge ReceiveMessage', name, args);
                    (_window___InAppBridgeDevToolsHook = window.__InAppBridgeDevToolsHook) === null || _window___InAppBridgeDevToolsHook === void 0 ? void 0 : _window___InAppBridgeDevToolsHook.onMessageReceive(message);
                    (_eventListeners_get = eventListeners.get(name)) === null || _eventListeners_get === void 0 ? void 0 : _eventListeners_get.forEach(function(handler) {
                        handler.apply(void 0, _to_consumable_array(args));
                    });
                }
            };
            var transport = transportOptions.map(function(makeTransport) {
                return makeTransport(onListen, targetHint !== null && targetHint !== void 0 ? targetHint : {});
            }).find(function(result) {
                return result !== null;
            });
            if (!transport) {
                return;
            }
            var destroy = transport.initialize();
            InAppBridge.initialized = true;
            InAppBridge.features = transport.features;
            InAppBridge.transport = transport;
            InAppBridge.targetHint = targetHint;
            var _targetHint_version, _parseVersion;
            InAppBridge.versionHint = (_parseVersion = parseVersion((_targetHint_version = targetHint === null || targetHint === void 0 ? void 0 : targetHint.version) !== null && _targetHint_version !== void 0 ? _targetHint_version : '')) !== null && _parseVersion !== void 0 ? _parseVersion : undefined;
            InAppBridge.destroy = function() {
                eventListeners.clear();
                destroy();
                InAppBridge.initialized = false;
            };
            (_window___InAppBridgeDevToolsHook = window.__InAppBridgeDevToolsHook) === null || _window___InAppBridgeDevToolsHook === void 0 ? void 0 : (_window___InAppBridgeDevToolsHook_onTransportActive = _window___InAppBridgeDevToolsHook.onTransportActive) === null || _window___InAppBridgeDevToolsHook_onTransportActive === void 0 ? void 0 : _window___InAppBridgeDevToolsHook_onTransportActive.call(_window___InAppBridgeDevToolsHook, InAppBridge.transport.name, InAppBridge.targetHint);
        },
        addListener: function addListener(eventName, handler) {
            var once = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
            var handlerFunction = function() {};
            var removeHandler = function() {
                var _eventListeners_get;
                var newListeners = ((_eventListeners_get = eventListeners.get(eventName)) !== null && _eventListeners_get !== void 0 ? _eventListeners_get : []).filter(function(listener) {
                    return listener !== handlerFunction;
                });
                if (!newListeners.length) {
                    eventListeners.delete(eventName);
                } else {
                    eventListeners.set(eventName, newListeners);
                }
            };
            handlerFunction = !once ? handler : function() {
                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                    args[_key] = arguments[_key];
                }
                removeHandler();
                handler.apply(void 0, _to_consumable_array(args));
            };
            var _eventListeners_get;
            eventListeners.set(eventName, _to_consumable_array((_eventListeners_get = eventListeners.get(eventName)) !== null && _eventListeners_get !== void 0 ? _eventListeners_get : []).concat([
                handlerFunction
            ]));
            return removeHandler;
        },
        createMessage: function createMessage(functionName) {
            for(var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
                args[_key - 1] = arguments[_key];
            }
            var messageId = createMessageId();
            var message = {
                messageId: messageId,
                name: functionName,
                args: args,
                inApp: true
            };
            return message;
        },
        sendCreatedMessage: function sendCreatedMessage(message) {
            debug('InAppBridge SendMessage', message.name, message.args);
            if (!InAppBridge.isInApp) {
                return false;
            }
            try {
                var _InAppBridge_transport;
                (_InAppBridge_transport = InAppBridge.transport) === null || _InAppBridge_transport === void 0 ? void 0 : _InAppBridge_transport.send(message);
                return true;
            } catch (error) {
                debug('InAppBridge SendMessageError', error);
                return false;
            }
        },
        sendMessage: function sendMessage(functionName) {
            for(var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
                args[_key - 1] = arguments[_key];
            }
            var _InAppBridge;
            var message = (_InAppBridge = InAppBridge).createMessage.apply(_InAppBridge, [
                functionName
            ].concat(_to_consumable_array(args)));
            var isSuccessful = InAppBridge.sendCreatedMessage(message);
            return isSuccessful ? message.messageId : null;
        },
        sendPromiseMessage: function sendPromiseMessage(functionName, config) {
            for(var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++){
                args[_key - 2] = arguments[_key];
            }
            var _InAppBridge;
            var message = (_InAppBridge = InAppBridge).createMessage.apply(_InAppBridge, [
                functionName
            ].concat(_to_consumable_array(args)));
            var messageId = message.messageId;
            return new Promise(function(resolve) {
                var hasReceived = false;
                var removeListener = InAppBridge.addListener("".concat(functionName, "/").concat(messageId), function() {
                    for(var _len = arguments.length, responseArguments = new Array(_len), _key = 0; _key < _len; _key++){
                        responseArguments[_key] = arguments[_key];
                    }
                    hasReceived = true;
                    if (config.responseValidator.guard(responseArguments)) {
                        resolve([
                            undefined,
                            responseArguments
                        ]);
                        return;
                    }
                    resolve([
                        {
                            error: true,
                            reason: 'receive:validation-fail',
                            receivedArguments: responseArguments
                        },
                        undefined
                    ]);
                }, true);
                var isSuccessful = InAppBridge.sendCreatedMessage(message);
                if (!isSuccessful && !hasReceived) {
                    removeListener();
                    resolve([
                        {
                            error: true,
                            reason: 'send:fail'
                        },
                        undefined
                    ]);
                    return;
                }
                if (config.timeout) {
                    var cleanup = function() {
                        if (!hasReceived) {
                            removeListener();
                            resolve([
                                {
                                    error: true,
                                    reason: 'receive:timeout'
                                },
                                undefined
                            ]);
                        }
                    };
                    setTimeout(cleanup, config.timeout);
                }
            });
        },
        log: {
            /**
       * @description 커스텀 이벤트 발송
       * */ event: function event(screenName, action, target, params) {
                var logEventParams = {
                    screenName: screenName,
                    action: action,
                    target: target
                };
                /**
         * 파라미터 개수가 너무 많으면 firebase에 이벤트가 누락될 가능성이 있기 때문에 25개로 제한
         */ if (params) {
                    var filteredParams = {};
                    Object.entries(params).forEach(function(param) {
                        var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
                        if (Object.getOwnPropertyNames(filteredParams).length >= EVENT_PARAM_NUMBER_LIMIT) {
                            return;
                        }
                        filteredParams[key] = value;
                    });
                    logEventParams.params = filteredParams;
                }
                InAppBridge.sendMessage('logEvent', logEventParams);
            },
            /**
       * @description 웹뷰용 페이지뷰 이벤트 발송
       * */ pageView: function pageView() {
                var data = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
                if (!isBrowser()) {
                    return;
                }
                var isDirectAccess = function(referrer) {
                    return referrer.length === 0;
                };
                var logPageViewParams = {};
                var userIdx = data.user_idx;
                var referrerVerbose = data.referrer || document.referrer;
                var locationVerbose = data.location || window.location.href;
                var locationUrl = new URL(locationVerbose);
                if (userIdx) {
                    logPageViewParams.user_idx = userIdx;
                }
                logPageViewParams.referrer = isDirectAccess(referrerVerbose) ? '' : new URL(referrerVerbose).origin + new URL(referrerVerbose).pathname;
                logPageViewParams.location = locationUrl.origin + locationUrl.pathname;
                locationUrl.searchParams.forEach(function(value, key) {
                    if (Object.getOwnPropertyNames(logPageViewParams).length >= EVENT_PARAM_NUMBER_LIMIT) {
                        return;
                    }
                    logPageViewParams["location_".concat(key)] = value;
                });
                InAppBridge.sendMessage('logPageView', logPageViewParams);
            },
            /**
       * @description 체크아웃 시작 이벤트 발송
       * */ beginCheckout: function beginCheckout(data) {
                InAppBridge.sendMessage('logBeginCheckout', data);
            },
            /**
       * @description 결제 이벤트 발송
       * */ purchase: function purchase(data) {
                InAppBridge.sendMessage('logPurchase', data);
            },
            /**
       * @description 충전후결제 이벤트 발송
       * */ purchaseChargeAndPay: function purchaseChargeAndPay(data) {
                InAppBridge.sendMessage('logPurchaseChargeAndPay', data);
            }
        },
        info: {
            version: function version() {
                return _async_to_generator(function() {
                    return _ts_generator(this, function(_state) {
                        throw new Error('Unimplemented function!');
                    });
                })();
            },
            inAppInfo: function inAppInfo() {
                return _async_to_generator(function() {
                    var inAppInfo, _inAppInfo_, inAppInfoResult;
                    return _ts_generator(this, function(_state) {
                        switch(_state.label){
                            case 0:
                                if (InAppBridge.versionHint && isVersionAOlder(InAppBridge.versionHint, appVersion(_templateObject()))) {
                                    return [
                                        2,
                                        {}
                                    ];
                                }
                                return [
                                    4,
                                    InAppBridge.sendPromiseMessage('inAppInfo', {
                                        responseValidator: Tuple(RInAppInfo),
                                        timeout: 3000
                                    })
                                ];
                            case 1:
                                inAppInfo = _state.sent();
                                if (inAppInfo[1]) {
                                    _inAppInfo_ = _sliced_to_array(inAppInfo[1], 1), inAppInfoResult = _inAppInfo_[0];
                                    return [
                                        2,
                                        inAppInfoResult
                                    ];
                                }
                                return [
                                    2,
                                    {}
                                ];
                        }
                    });
                })();
            }
        },
        control: {
            /**
       * @description 앱 인증 성공시의 콜백 함수
       * */ authorizeSuccess: function authorizeSuccess(properties) {
                InAppBridge.sendMessage('authorizeSuccess', properties);
            },
            /**
       * @description 검색결과 페이지의 필터값을 앱의 인스턴스 서치의 토글값과 연동하기 위한 함수
       * */ changeAdultExclude: function changeAdultExclude(properties) {
                InAppBridge.sendMessage('changeAdultExclude', properties);
            },
            /**
       * @description 현재 웹뷰를 닫는 함수
       * */ close: function close() {
                InAppBridge.sendMessage('close');
            },
            /**
       * @description 앱에서 로그인 페이지를 여는 함수
       * */ openLogin: function openLogin() {
                InAppBridge.sendMessage('openLogin');
            },
            /**
       * @description 앱에서 리디캐시 충전 페이지를 여는 함수
       * */ openCashCharge: function openCashCharge() {
                InAppBridge.sendMessage('openCashCharge');
            },
            /**
       * @description 앱에서 자동충전 페이지를 여는 함수 type이 'balance'인 경우 잔액 기준 자동충전 페이지를 열고, 'period'인 경우 매월 1일 자동충전 페이지를 열음
       */ openAutoCharge: function openAutoCharge(properties) {
                InAppBridge.sendMessage('openAutoCharge', properties);
            },
            /**
       * @description 앱에서 카트 페이지를 여는 함수
       */ openCart: function openCart() {
                InAppBridge.sendMessage('openCart');
            },
            /**
       * @description 앱에서 토스트를 여는 함수
       */ openToast: function openToast(properties) {
                InAppBridge.sendMessage('openToast', properties);
            },
            /**
       * @description 앱에서 성인인증 페이지를 여는 함수
       * */ openAdultVerify: function openAdultVerify() {
                InAppBridge.sendMessage('openAdultVerify');
            },
            /**
       * @description 앱에서 공유기능을 여는 함수
       */ openShare: function openShare(properties) {
                InAppBridge.sendMessage('openShare', properties);
            },
            /**
       * @description inapp/checkout 에서 결제 완료 시의 콜백 함수
       * */ purchaseSuccess: function purchaseSuccess(properties) {
                InAppBridge.sendMessage('purchaseSuccess', properties);
            },
            /**
       * @description inapp/checkout 에서 결제 실패 시의 콜백 함수
       * */ purchaseFailure: function purchaseFailure(message, properties) {
                InAppBridge.sendMessage('purchaseFailure', message, properties);
            },
            /**
       * @description 회원탈퇴 완료
       * */ secedeSuccess: function secedeSuccess() {
                InAppBridge.sendMessage('secedeSuccess');
            },
            /**
       * @description 비밀번호 검증 성공
       * */ validatePasswordSuccess: function validatePasswordSuccess() {
                InAppBridge.sendMessage('validatePasswordSuccess');
            }
        },
        /**
     * 인앱브릿지 도입 전 앱에서 사용하고 있는 함수 대응을 위한 메소드
     * 확인된 사용처: 구 회원가입 웹뷰(account 레포)
     */ deprecated: {
            /**
       * @description inAppBridge.control.close()의 하위버전용
       * */ close: function close() {
                InAppBridge.sendMessage('finish');
            },
            /**
       * @description inAppBridge.control.authorizeSuccess()의 하위버전용
       * */ authorizeSuccess: function authorizeSuccess(properties) {
                InAppBridge.sendMessage('onAuthTokenIssued', properties.accessToken, properties.refreshToken);
            },
            /**
       * @description inAppBridge에 대응되는 메소드 없음. 순수 구 앱용
       * */ openBrowser: function openBrowser(href) {
                InAppBridge.sendMessage('openBrowser', href);
            }
        },
        get isInApp () {
            return !!InAppBridge.transport;
        }
    };
    return InAppBridge;
};

export { Currency, NativeTransport, ReactNativeTransport, ServiceType, TargetHintPlatform, TargetHintWebView, appVersion, createInAppBridge, isVersionAOlder, parseVersion, transportOptions };
