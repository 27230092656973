import 'normalize.css';
import 'intersection-observer';

import { RIDI_FFID_KEY, syncReferralCode } from '@ridi-web/common';
import { PopupProvider } from '@ridi-web/design-system/Popup/cjs';
import { Toast as DesignSystemToast, ToastProvider } from '@ridi-web/design-system/Toast/cjs';
import { TrackingDevTools } from '@ridi-web/tracking';
import App, { AppProps, NextWebVitalsMetric } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { DehydratedState, Hydrate, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import smoothscroll from 'smoothscroll-polyfill';

import { User } from '@/base/interfaces/User';
import { CsrfTokenProvider } from '@/components/common/CsrfTokenProvider';
import { FeatureFlagProvider } from '@/components/common/FeatureFlag';
import { GlobalErrorBoundary } from '@/components/common/GlobalErrorBoundary/GlobalErrorBoundary';
import { PopupLayerProvider } from '@/components/common/PopupLayer/PopupLayerContext';
import { ToastProps } from '@/components/common/Toast';
import { TrackingInitializer } from '@/components/common/TrackingInitializer';
import {
  ViewEventObserverProvider,
  ZeroThresholdViewEventObserverProvider,
} from '@/components/common/ViewEventObserver/ViewEventObserver';
import { UserInfoProvider } from '@/components/inapp/account/UserInfoProvider';
import { AppThemeProvider, ThemeStringType } from '@/components/styles/AppThemeProvider';
import { EmotionClientSideCacheProvider } from '@/components/styles/EmotionCacheProvider';
import { setLoggedUserAction } from '@/features/global/auth/authSlice';
import { setFeatureFlagAction } from '@/features/global/featureFlag/featureFlagSlice';
import { variablesSlice } from '@/features/global/variables/variablesSlice';
import { InitializeAppProvider } from '@/hooks/useInitializeApp';
import { usePerfumeJs } from '@/hooks/usePerfumeJs';
import ErrorPage from '@/pages/_error.page';
import { isErrorPageProps, wrapper } from '@/pages/wrapper';
import { generateQueryClient } from '@/queries/base';
import { fetchFeatureFlags } from '@/services/backendsApi/v2/featureFlag/featureFlagService';
import { isServer } from '@/services/baseService';
import { debug } from '@/utils/debug';
import { FEATURE_FLAG_KEYS, type FeatureFlag, getFeatureFlagValueByKey } from '@/utils/featureFlag';
import { InAppBridge } from '@/utils/inappBridge';
import sendException from '@/utils/sentry/sendException';

const Toast = dynamic<ToastProps>(() => import('@/components/common/Toast').then(mod => mod.Toast), {
  ssr: false,
});

InAppBridge.initialize();

if (!isServer()) {
  smoothscroll.polyfill();
}

const log = debug('RidibooksApp');

interface PagePropsWithDehydratedClient extends Any {
  dehydratedState?: DehydratedState;
}

interface RidibooksAppProps extends AppProps {
  themeString?: ThemeStringType;
  featureFlag?: FeatureFlag;
  ffid?: string;
  isInApp: boolean;
}

const RidibooksApp = ({
  Component,
  pageProps,
  themeString,
  featureFlag,
  ffid,
  isInApp,
}: RidibooksAppProps): ReactJSX.Element => {
  const queryClient = useMemo(() => generateQueryClient(), []);
  const { dehydratedState } = pageProps as PagePropsWithDehydratedClient;

  const referralEventData = getFeatureFlagValueByKey({
    featureFlag,
    featureFlagKey: 'servicebackends-referral-event-20240820',
  });
  const router = useRouter();
  useEffect(() => {
    syncReferralCode({ referralEventData });
  }, [referralEventData, router]);

  usePerfumeJs();

  if (isErrorPageProps(pageProps)) {
    const { statusCode, title, message } = pageProps.error;
    return (
      <AppThemeProvider themeString={themeString}>
        <QueryClientProvider client={queryClient}>
          <FeatureFlagProvider featureFlag={featureFlag}>
            <ErrorPage statusCode={statusCode} title={title} message={message} isInApp={isInApp} />
          </FeatureFlagProvider>
        </QueryClientProvider>
      </AppThemeProvider>
    );
  }

  return (
    <EmotionClientSideCacheProvider>
      <AppThemeProvider themeString={themeString}>
        <FeatureFlagProvider featureFlag={featureFlag}>
          <TrackingInitializer ffid={ffid}>
            <ViewEventObserverProvider>
              <ZeroThresholdViewEventObserverProvider>
                <QueryClientProvider client={queryClient}>
                  <UserInfoProvider>
                    {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen />}
                    {(process.env.NODE_ENV === 'development' || process.env.RUN_MODE !== 'production') && (
                      <TrackingDevTools defaultEnabled={process.env.NODE_ENV === 'development'} />
                    )}
                    <Hydrate state={dehydratedState}>
                      <CsrfTokenProvider>
                        <ToastProvider>
                          <PopupProvider>
                            <PopupLayerProvider>
                              <GlobalErrorBoundary>
                                <Component {...pageProps} />
                              </GlobalErrorBoundary>
                              <Toast />
                              <DesignSystemToast />
                            </PopupLayerProvider>
                          </PopupProvider>
                        </ToastProvider>
                      </CsrfTokenProvider>
                    </Hydrate>
                  </UserInfoProvider>
                </QueryClientProvider>
              </ZeroThresholdViewEventObserverProvider>
            </ViewEventObserverProvider>
          </TrackingInitializer>
        </FeatureFlagProvider>
      </AppThemeProvider>
    </EmotionClientSideCacheProvider>
  );
};

const NextApp = (appProps: RidibooksAppProps) => (
  // eslint-disable-next-line react/destructuring-assignment
  <InitializeAppProvider featureFlag={appProps.featureFlag}>
    <RidibooksApp {...appProps} />
  </InitializeAppProvider>
);

NextApp.getInitialProps = wrapper.getInitialAppProps(store => async appContext => {
  let themeString;
  let addFinishMetric;
  let featureFlag: FeatureFlag | undefined;
  let ffid: string | undefined;
  let isInApp = false;

  if (isServer()) {
    const { req } = appContext.ctx;
    featureFlag = req.FeatureFlag ?? [];
    ffid = req.cookies?.[RIDI_FFID_KEY];
    isInApp = !!req.Variables?.app;

    const variables = req?.Variables;
    const { setVariablesAction } = variablesSlice.actions;

    addFinishMetric = req.addMetric?.('RidibooksApp__getInitialProps');
    themeString = variables?.app?.theme;

    if (featureFlag) {
      store.dispatch(setFeatureFlagAction(featureFlag));
    }
    if (variables) {
      store.dispatch(setVariablesAction(variables));
    }

    let loggedUser: User | null = null;
    const user = req?.User;
    if (user) {
      loggedUser = {
        isVerifiedAdult: false,
        adultVerification: { status: 'NOT_VERIFIED' },
        email: '',
        id: '',
        idx: user.idx,
      };
    }

    store.dispatch(setLoggedUserAction({ loggedUser }));
  } else {
    isInApp = !!store.getState().global.variables?.variables?.app;

    if (FEATURE_FLAG_KEYS.length > 0) {
      const [error, response] = await fetchFeatureFlags(FEATURE_FLAG_KEYS, appContext.ctx.req);

      if (error) {
        sendException(error, { level: 'fatal' });
      } else {
        featureFlag = response.Data.data as FeatureFlag;
      }
    }
  }

  const appProps = await App.getInitialProps(appContext);

  addFinishMetric?.();
  return { ...appProps, themeString, featureFlag, ffid, isInApp };
});

const sendToGTM = (metric: NextWebVitalsMetric): void => {
  if (typeof window === undefined || !window.dataLayer) {
    return;
  }

  window.dataLayer.push({
    event: 'web-vitals',
    event_category: 'Web Vitals',
    event_action: metric.name,
    event_value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value),
    event_label: metric.id,
  });
};

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  log('metric', metric);
  sendToGTM(metric);
}

// @internal
export type { RidibooksAppProps };

export default wrapper.withRedux(NextApp);
