import { useSingleFeatureFlag } from '@/hooks/useSingleFeatureFlag';

export const useSearchImprovementExperiment = () => {
  const { isPending, error, data } = useSingleFeatureFlag('servicebackends-search-components');

  return {
    data: data?.landing ?? [],
    isFallback: !!error || (!isPending && !data?.landing?.length), // 레이아웃을 얻지 못하면 기존 레이아웃으로 동작
    isPending,
  };
};
