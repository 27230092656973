import { css } from '@emotion/react';
import { BreakPoint, orBelow } from '@ridi-web/design-system/Styles';

import { RIDITheme } from '@/components/styles/themes.js';

export { historyHeaderStyle, historyHeaderTextStyle, historyNoEntryStyle } from './SearchHistory.styles';

export const historyRightGroupStyle = (theme: RIDITheme) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme.colors.fillSecondary};
`;

export const historyRightGroupDividerStyle = (theme: RIDITheme) => css`
  width: 1px;
  height: 10px;
  margin: 0 8px;
  background-color: ${theme.colors.grey400};
`;

export const historyItemsContainerStyle = css`
  padding-top: 8px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      overflow-x: scroll;
      scrollbar-width: none;

      &::scrollbar {
        height: 0;
      }

      &::-webkit-scrollbar {
        height: 0;
      }
    `,
  )}
`;

export const historyOptionButtonStyle = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  outline: none;
`;

export const historyItemsExperimentalStyle = css`
  display: flex;
  padding-left: 16px;
  flex-wrap: wrap;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      flex-wrap: nowrap;

      &:after {
        content: '$';
        width: 16px;
        height: 16px;
        display: block;
        color: transparent;
        pointer-events: none;
      }
    `,
  )}
`;

export const historyItemStyle = (theme: RIDITheme) => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  height: 36px;
  border-radius: 6px;
  border: 1px solid ${theme.colors.grey300};
  padding: 0 8px 0 10px;
  margin-right: 8px;
  margin-bottom: 8px;

  &:hover {
    background-color: #fafafa;
  }
`;

export const historyItemLinkStyle = (theme: RIDITheme) => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 144px;
  font-size: 14px;
  line-height: 1.2em;
  color: ${theme.colors.black};
`;

export const historyItemRemoveExperimentalStyle = css`
  height: 1em;
`;

export const historyItemRemoveIconStyle = (theme: RIDITheme) => css`
  width: 12px;
  height: 12px;
  margin-left: 4px;
  color: ${theme.colors.grey500};
`;
