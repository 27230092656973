import { useEffect as T } from "react";
const s = "referralCode", u = "ridi-referral-event", V = "ridi_app_theme", F = "ridi_nav", $ = "ridi_auth", j = "adult_exclude", z = "adult_include", M = "login_time", G = "deauth_watchdog_servertoken", H = "use_grid", B = "from_account_modify", W = "fingerprint", q = "add_pwa_requested", Q = "review-tab", X = "FIG", J = "ridi-at", Z = "ridi-rt", ee = "ridi-al", te = "user_device_type", re = "ridi_app", ne = "ridi_app_version", ie = "ridi-ffid", oe = "PHPSESSID", ce = "logout_time", ae = "__books__", Ee = "server_metrics", se = "ruid", _e = "ridi-referral-event", de = "app_instance_id", ue = "app_ga_session_id", Ie = "firebase_app_id", fe = "appsflyer_id", le = "inpay", Pe = "_rdt_info", Re = "iPod", De = "iPhone", pe = "iPad", he = "Android", me = "AndroidTab", Ae = "PC", Te = "WindowsPhone", Ce = "WindowsCE", Se = "EtcMobileDevice", ge = "PAPER", Oe = "PAPER Lite", ve = "PAPER PRO", xe = "RIDIPAPER", we = "RIDIPAPER 4";
/*! js-cookie v3.0.5 | MIT */
function l(t) {
  for (var n = 1; n < arguments.length; n++) {
    var e = arguments[n];
    for (var c in e)
      t[c] = e[c];
  }
  return t;
}
var C = {
  read: function(t) {
    return t[0] === '"' && (t = t.slice(1, -1)), t.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
  },
  write: function(t) {
    return encodeURIComponent(t).replace(
      /%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
      decodeURIComponent
    );
  }
};
function R(t, n) {
  function e(o, r, i) {
    if (!(typeof document > "u")) {
      i = l({}, n, i), typeof i.expires == "number" && (i.expires = new Date(Date.now() + i.expires * 864e5)), i.expires && (i.expires = i.expires.toUTCString()), o = encodeURIComponent(o).replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent).replace(/[()]/g, escape);
      var E = "";
      for (var a in i)
        i[a] && (E += "; " + a, i[a] !== !0 && (E += "=" + i[a].split(";")[0]));
      return document.cookie = o + "=" + t.write(r, o) + E;
    }
  }
  function c(o) {
    if (!(typeof document > "u" || arguments.length && !o)) {
      for (var r = document.cookie ? document.cookie.split("; ") : [], i = {}, E = 0; E < r.length; E++) {
        var a = r[E].split("="), f = a.slice(1).join("=");
        try {
          var d = decodeURIComponent(a[0]);
          if (i[d] = t.read(f, d), o === d)
            break;
        } catch {
        }
      }
      return o ? i[o] : i;
    }
  }
  return Object.create(
    {
      set: e,
      get: c,
      remove: function(o, r) {
        e(
          o,
          "",
          l({}, r, {
            expires: -1
          })
        );
      },
      withAttributes: function(o) {
        return R(this.converter, l({}, this.attributes, o));
      },
      withConverter: function(o) {
        return R(l({}, this.converter, o), this.attributes);
      }
    },
    {
      attributes: { value: Object.freeze(n) },
      converter: { value: Object.freeze(t) }
    }
  );
}
var I = R(C, { path: "/" });
const S = /^https:\/\/((next|view|preflight)\.local\.)?ridibooks\.com.*$/;
async function g({ referralEventData: t }) {
  if (!globalThis.location)
    return;
  const n = new URL(globalThis.location.href), e = Object.fromEntries(n.searchParams.entries());
  let c = !1;
  if (t) {
    const o = new Date(t.eventStartAt), r = new Date(t.eventEndAt), i = o.getTime(), E = r.getTime();
    if (!Number.isNaN(i) && !Number.isNaN(E)) {
      const a = Date.now();
      c = !(i <= a && a <= E);
    }
  }
  if (c) {
    if (e[s] || I.get(u)) {
      const { [s]: o, ...r } = e;
      I.remove(u, {
        domain: ".ridibooks.com",
        path: "/",
        secure: !0
      });
      const i = `${n.pathname}?${new URLSearchParams(r).toString()}`;
      globalThis.history.replaceState({}, "", i);
    }
    return;
  }
  if (S.test(globalThis.location.href) && (s in e && e[s] && I.set(u, e[s], {
    path: "/",
    domain: ".ridibooks.com",
    secure: !0
  }), I.get(u) && !(s in e))) {
    const o = `${n.pathname}?${new URLSearchParams({
      ...e,
      [s]: I.get(u)
    }).toString()}`;
    globalThis.history.replaceState({}, "", o);
  }
}
const ye = (t, n) => {
  T(() => {
    g(t);
  }, [t.referralEventData, ...n]);
};
var _ = {};
Object.defineProperty(_, "__esModule", { value: !0 });
_.parse = N;
_.serialize = K;
const O = /^[\u0021-\u003A\u003C\u003E-\u007E]+$/, v = /^[\u0021-\u003A\u003C-\u007E]*$/, x = /^([.]?[a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?)([.][a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?)*$/i, w = /^[\u0020-\u003A\u003D-\u007E]*$/, y = Object.prototype.toString, Y = /* @__PURE__ */ (() => {
  const t = function() {
  };
  return t.prototype = /* @__PURE__ */ Object.create(null), t;
})();
function N(t, n) {
  const e = new Y(), c = t.length;
  if (c < 2)
    return e;
  const o = (n == null ? void 0 : n.decode) || U;
  let r = 0;
  do {
    const i = t.indexOf("=", r);
    if (i === -1)
      break;
    const E = t.indexOf(";", r), a = E === -1 ? c : E;
    if (i > a) {
      r = t.lastIndexOf(";", i - 1) + 1;
      continue;
    }
    const f = p(t, r, i), d = h(t, i, f), P = t.slice(f, d);
    if (e[P] === void 0) {
      let D = p(t, i + 1, a), m = h(t, a, D);
      const A = o(t.slice(D, m));
      e[P] = A;
    }
    r = a + 1;
  } while (r < c);
  return e;
}
function p(t, n, e) {
  do {
    const c = t.charCodeAt(n);
    if (c !== 32 && c !== 9)
      return n;
  } while (++n < e);
  return e;
}
function h(t, n, e) {
  for (; n > e; ) {
    const c = t.charCodeAt(--n);
    if (c !== 32 && c !== 9)
      return n + 1;
  }
  return e;
}
function K(t, n, e) {
  const c = (e == null ? void 0 : e.encode) || encodeURIComponent;
  if (!O.test(t))
    throw new TypeError(`argument name is invalid: ${t}`);
  const o = c(n);
  if (!v.test(o))
    throw new TypeError(`argument val is invalid: ${n}`);
  let r = t + "=" + o;
  if (!e)
    return r;
  if (e.maxAge !== void 0) {
    if (!Number.isInteger(e.maxAge))
      throw new TypeError(`option maxAge is invalid: ${e.maxAge}`);
    r += "; Max-Age=" + e.maxAge;
  }
  if (e.domain) {
    if (!x.test(e.domain))
      throw new TypeError(`option domain is invalid: ${e.domain}`);
    r += "; Domain=" + e.domain;
  }
  if (e.path) {
    if (!w.test(e.path))
      throw new TypeError(`option path is invalid: ${e.path}`);
    r += "; Path=" + e.path;
  }
  if (e.expires) {
    if (!b(e.expires) || !Number.isFinite(e.expires.valueOf()))
      throw new TypeError(`option expires is invalid: ${e.expires}`);
    r += "; Expires=" + e.expires.toUTCString();
  }
  if (e.httpOnly && (r += "; HttpOnly"), e.secure && (r += "; Secure"), e.partitioned && (r += "; Partitioned"), e.priority)
    switch (typeof e.priority == "string" ? e.priority.toLowerCase() : void 0) {
      case "low":
        r += "; Priority=Low";
        break;
      case "medium":
        r += "; Priority=Medium";
        break;
      case "high":
        r += "; Priority=High";
        break;
      default:
        throw new TypeError(`option priority is invalid: ${e.priority}`);
    }
  if (e.sameSite)
    switch (typeof e.sameSite == "string" ? e.sameSite.toLowerCase() : e.sameSite) {
      case !0:
      case "strict":
        r += "; SameSite=Strict";
        break;
      case "lax":
        r += "; SameSite=Lax";
        break;
      case "none":
        r += "; SameSite=None";
        break;
      default:
        throw new TypeError(`option sameSite is invalid: ${e.sameSite}`);
    }
  return r;
}
function U(t) {
  if (t.indexOf("%") === -1)
    return t;
  try {
    return decodeURIComponent(t);
  } catch {
    return t;
  }
}
function b(t) {
  return y.call(t) === "[object Date]";
}
var L = /* @__PURE__ */ ((t) => (t.BUYER = "buyer", t.ALL = "all", t))(L || {});
const Ye = (t) => t ? _.parse(t) : null, Ne = (t, n, e) => {
  if (n === void 0) {
    document.cookie = _.serialize(t, "", {
      ...e,
      expires: /* @__PURE__ */ new Date(0)
    });
    return;
  }
  document.cookie = _.serialize(t, n, e);
};
export {
  J as ACCESS_TOKEN_KEY,
  q as ADD_PWA_REQUESTED_KEY,
  j as ADULT_EXCLUDE_KEY,
  z as ADULT_INCLUDE_KEY,
  ee as AUTO_LOGIN_KEY,
  G as DEAUTH_WATCHDOG_SERVER_TOKEN_KEY,
  he as DEVICE_TYPE_ANDROID,
  me as DEVICE_TYPE_ANDROID_TABLET,
  Se as DEVICE_TYPE_ETC_MOBILE,
  pe as DEVICE_TYPE_IPAD,
  De as DEVICE_TYPE_IPHONE,
  Re as DEVICE_TYPE_IPOD,
  te as DEVICE_TYPE_KEY,
  ge as DEVICE_TYPE_PAPER,
  Oe as DEVICE_TYPE_PAPER_LITE,
  ve as DEVICE_TYPE_PAPER_PRO,
  Ae as DEVICE_TYPE_PC,
  xe as DEVICE_TYPE_RIDIPAPER,
  we as DEVICE_TYPE_RIDIPAPER4,
  Ce as DEVICE_TYPE_WINDOWS_CE,
  Te as DEVICE_TYPE_WINDOWS_PHONE,
  W as FINGERPRINT_KEY,
  B as FROM_ACCOUNT_MODIFY,
  le as INPAY,
  M as LOGIN_TIME_KEY,
  ce as LOGOUT_TIME_KEY,
  X as NAMESPACE,
  oe as PHP_SESSION_KEY,
  Pe as RDT_INFO,
  _e as REFERRAL_CODE,
  u as REFERRAL_COOKIE_KEY,
  s as REFERRAL_QUERY_KEY,
  Z as REFRESH_TOKEN_KEY,
  Q as REVIEW_TAB_KEY,
  fe as RIDI_APP_APPSFLYER_ID,
  Ie as RIDI_APP_FIREBASE_APP_ID,
  ue as RIDI_APP_GA_SESSION_ID,
  de as RIDI_APP_INSTANCE_ID,
  re as RIDI_APP_KEY,
  V as RIDI_APP_THEME_KEY,
  ne as RIDI_APP_VERSION_KEY,
  $ as RIDI_AUTH_INFO_KEY,
  ie as RIDI_FFID_KEY,
  F as RIDI_NAVIGATION_KEY,
  se as RUID,
  L as ReviewTab,
  Ee as SERVER_METRICS,
  ae as STORAGE_PREFIX,
  H as USE_GRID_BOOK_LISTING,
  Ye as getParsedCookie,
  Ne as setCookie,
  g as syncReferralCode,
  ye as useSyncReferralCode
};
