import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hydrateTemporaryAuthAction } from '@/features/global/actions';
import { isHydrateNeededSelector } from '@/features/global/isHydrateNeeded';
import { isRidiAppSelector } from '@/features/global/variables/variablesSlice';
import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect';
import { useLoggedUser } from '@/hooks/useLoggedUser';
import { getUId, initializeEventClient, sendInAppPageView, setFfid, setUId } from '@/utils/eventClient';
import { isBrowser } from '@/utils/isBrowser';
import sendException from '@/utils/sentry/sendException';

interface TrackingInitializerProps {
  children: ReactNode;
  ffid?: string;
}

const BLACK_LIST_PAGE = ['/partials'];

const isBlocked = !isBrowser() || BLACK_LIST_PAGE.includes(window.__NEXT_DATA__.page);

const inAppPageViewTargets = [
  '/account/secede',
  '/account/leave',
  '/inapp/account',
  '/inapp/search',
  '/views',
  '/order/checkout/finished',
  '/order/checkout/finished-charge',
  '/order/checkout/auto-charge',
];

export const TrackingInitializer = ({ children, ffid }: TrackingInitializerProps): ReactJSX.Element => {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const isHydrateNeeded = useSelector(isHydrateNeededSelector);
  const router = useRouter();
  const isRidiApp = useSelector(isRidiAppSelector);

  // 웹뷰 pageview에서 CSR시 사용할 referrer를 저장
  const referrer = useRef('');
  useEffect(() => {
    if (!isBrowser() || !isRidiApp) {
      return;
    }

    if (!inAppPageViewTargets.some(target => router.asPath.includes(target))) {
      return;
    }

    sendInAppPageView({ userIdx: user?.idx, referrer: referrer.current || document.referrer });
    referrer.current = `${window.location.origin}${router.asPath}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRidiApp, router.asPath]);

  const isUidInitialized = useRef(false);

  useIsomorphicLayoutEffect(() => {
    /**
     * init 된 순간에 GA4 향상된 설정에 의해 page_view는 자동적으로 전송된다.
     */
    initializeEventClient();
    setFfid(ffid);

    /**
     * block처리 되었거나, uid 세팅이 끝났다면 초기화 작업을 수행하지 않는다.
     */
    if (isBlocked || isUidInitialized.current) {
      return;
    }

    const eventClientUid = getUId();
    const { props, ...sentryPayload } = window.__NEXT_DATA__;
    if (isHydrateNeeded) {
      const { loggedUser: temporaryUser } = dispatch(hydrateTemporaryAuthAction()).payload;
      if (temporaryUser && eventClientUid === undefined) {
        setUId(temporaryUser.idx || null);

        if (temporaryUser.idx === 0) {
          sendException(new Error('Unexpected temp uidx 0'), { level: 'warning', extra: { payload: sentryPayload } });
        }
      }
    } else if (user && eventClientUid === undefined) {
      setUId(user.idx || null);

      if (user.idx === 0) {
        sendException(new Error('Unexpected uidx 0'), { level: 'warning', extra: { payload: sentryPayload } });
      }
    }

    isUidInitialized.current = true;
  }, [isHydrateNeeded, user?.idx, user?.id]);

  useEffect(
    () => () => {
      isUidInitialized.current = false;
    },
    [router.asPath],
  );

  return <>{children}</>;
};
