import { css, SerializedStyles } from '@emotion/react';

import { ZIndexLayer } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';
import { clearOutline } from '@/components/styles/reset';
import { RIDITheme } from '@/components/styles/themes';
import { rgba } from '@/utils/rgba';

export const formStyle = css`
  display: flex;
  align-items: flex-start;
`;

export const formFocusedStyle = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: ${ZIndexLayer.LEVEL_OVERLAY};
      touch-action: none;
    `,
  )}
`;

export const formPlaceholderStyle = css`
  display: none;
  order: 4;
  width: 100%;
  height: 40px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      display: flex;
    `,
  )};
`;

export const focusTrapStyle = (theme: RIDITheme) => css`
  ${clearOutline};
  min-width: 0;
  flex: 1;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 100%;
      background: ${theme.colors.bgBase};
    `,
  )};
`;

export const searchBoxWrapperStyle = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0 16px;
      padding-top: 9px;
      padding-bottom: 5px;
    `,
  )}
`;

export const searchBoxWrapperFocusedStyle = (theme: RIDITheme): SerializedStyles => css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      display: flex;
      align-items: center;
      padding-top: 5px;
      background-color: ${theme.colors.bgBase};
    `,
  )}
`;

export const searchBoxWrapperUnfocusedStyle = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0;
    `,
  )}
`;

export const searchBoxShapeStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.grey100};
  border: 1px solid rgba(0, 0, 0, 0.5%);
  border-radius: 8px;

  display: flex;
  align-items: center;
  padding: 10px;
  height: 40px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 100%;
      padding: 0 9px;
    `,
  )}
`;

export const searchBoxWrapper2Style = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0;
    `,
  )}
`;

export const searchBoxStyle = (theme: RIDITheme): SerializedStyles => css`
  flex: 1;
  min-width: 0;
  height: 100%;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: ${theme.colors.fillPrimary};
  caret-color: ${theme.colors.blue};

  &::selection {
    background: ${rgba(theme.colors.blue, 0.08)};
  }
`;

export const searchIconStyle = (theme: RIDITheme): SerializedStyles => css`
  flex: none;
  color: ${theme.colors.grey500};
  font-size: 20px;
  margin-right: 12px;
  opacity: 1;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      color: ${theme.colors.fillSecondary};
      font-size: 20px;
      margin-right: 10px;
    `,
  )};
`;

export const clearStyle = (theme: RIDITheme): SerializedStyles => css`
  flex: none;
  display: block;
  font-size: 16px;
  margin: 5px;
  color: ${theme.colors.fillSecondary};
`;

export const resetButtonStyle = css`
  outline: none;
  margin-left: 7px;
  margin-right: -5px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-right: -3px;
    `,
  )};
`;

export const cancelButtonStyle = (theme: RIDITheme): SerializedStyles => css`
  display: none;
  flex: none;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: ${theme.colors.fillPrimary};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      display: block;
      margin-left: 16px;
    `,
  )};
`;

export const popupWrapperStyle = (theme: RIDITheme): SerializedStyles => css`
  /*
PC에서 로그아웃시 검색 팝업이 반응형으로 줄어들게 하기 위한 수치
https://app.asana.com/0/1208942642423663/1209050252959007
정확하게 작동하려면 창 가로 길이 픽셀이 필요하지만 CSS에서 제공하지 않으니 vw로 근사하게 구현
width 속성에서 범위 내 값을 무작위로 입력하고 창 크기를 변경해서 여백이 유지되는 값을 찾는 방식으로 도출
*/
  width: calc(-166px + 47vw);
  min-width: 391px;
  max-width: 464px;

  position: absolute;
  margin-top: 10px;
  padding-top: 6px;
  border: 1px solid ${theme.colors.grey200};
  border-radius: 8px;
  background: ${theme.colors.bgBase};

  overflow: hidden;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.08);
  z-index: ${ZIndexLayer.LEVEL_OVERLAY};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      position: static;
      min-width: initial;
      width: 100%;
      max-width: initial;
      margin-top: 0;
      padding-top: 0;
      border-radius: 0;
      border: none;
      box-shadow: none;

      opacity: 0;
    `,
  )};
`;

export const popupWrapperFocusedStyle = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      opacity: 1;
    `,
  )};
`;

export const popupContentStyle = css`
  margin: -1px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin: 0;
    `,
  )};
`;
